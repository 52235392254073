<template>
  <a-modal
      :title="applyType == 1 ? '新增' : applyType == 2 ? '编辑' : '详情'"
      :visible="visible"
      @cancel="visible = false"
      :footer="null"
      :maskClosable="false"
      :width="760">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
  >
      <a-form-model-item label="分销商" prop="distributorCode">
        <a-select :disabled="applyType == 3" v-model="form.distributorCode" placeholder="请选择分销商">
          <a-select-option value="20220601001" label="优加惠品">优加惠品</a-select-option>
        </a-select>
      </a-form-model-item>
    <a-form-model-item label="活动类型" prop="type">
      <a-select :disabled="applyType == 3" v-model="form.type" placeholder="请选择活动类型">
        <a-select-option :value="1">
          健步走
        </a-select-option>
        <a-select-option :value="2">
          知识竞答
        </a-select-option>
      </a-select>
    </a-form-model-item>

      <a-form-model-item ref="theme" prop="sellerId" label="卖方主体">
        <a-select v-model="form.sellerId" style="width: 100%" :disabled="applyType == 3" placeholder="请选择卖方主体">
          <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="theme" label="活动主题" prop="theme" >
        <a-input v-model="form.theme" placeholder="请输入活动主题" :maxLength="20" :disabled="applyType == 3" />
      </a-form-model-item>
    <a-form-model-item label="活动时间" prop="startTime" v-if="applyType != 3">
      <a-range-picker v-if="applyType == 1" :value="dateValue" :ranges="ranges" :show-time="{ format: 'HH:mm:ss' }" style="width: 100%"  format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']" @change="dateChange"  />
<!--      <a-range-picker v-if="applyType == 2" :value="dateValue" :default-value="[formatDateMin(form.startTime), formatDateMin(form.endTime)]" :ranges="ranges" :show-time="{ format: 'HH:mm:ss' }" style="width: 100%"  format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']" @change="dateChange"  />-->
      <a-range-picker v-if="applyType == 2" :value="dateValue" :ranges="ranges" :show-time="{ format: 'HH:mm:ss' }" style="width: 100%"  format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']" @change="dateChange"  />
    </a-form-model-item>
      <a-form-model-item label="活动时间" prop="startTime" v-if="applyType == 3">

        {{formatDateMin(form.startTime)}} 至 {{formatDateMin(form.endTime)}}
      </a-form-model-item>

      <a-form-model-item label="用户端展示时间" prop="showTime" v-if="applyType != 3">
        <a-range-picker v-if="applyType == 1" :value="showTimeValue" :ranges="ranges" :show-time="{ format: 'HH:mm:ss' }" style="width: 100%"  format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']" @change="dateShowChange"  />
<!--        <a-range-picker v-if="applyType == 2" :value="showTimeValue" :ranges="ranges" :show-time="{ format: 'HH:mm:ss' }" style="width: 100%" :default-value="[formatDateMin(form.showTime), formatDateMin(form.closeTime)]" format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']" @change="dateShowChange"  />-->
        <a-range-picker v-if="applyType == 2" :value="showTimeValue" :ranges="ranges" :show-time="{ format: 'HH:mm:ss' }" style="width: 100%"  format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']" @change="dateShowChange"  />
      </a-form-model-item>
      <a-form-model-item label="用户端展示时间" prop="showTime" v-if="applyType == 3">
        {{formatDateMin(form.showTime)}} 至 {{formatDateMin(form.closeTime)}}
      </a-form-model-item>

      <a-form-model-item label="奖品形式" prop="prizeFormat" >
<!--        1 储值卡/券 2 实物礼品 3 电影次票 4 企业自行采购 5 其他'-->
        <a-select v-model="form.prizeFormat" placeholder="请选择奖品形式" :disabled="applyType == 3">
            <a-select-option :value="1">储值卡/券</a-select-option>
            <a-select-option :value="2">实物礼品</a-select-option>
            <a-select-option :value="3">电影次票</a-select-option>
            <a-select-option :value="4">企业自行采购</a-select-option>
            <a-select-option :value="5">其他</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item ref="eventSalesAmount" label="活动售卖金额" prop="eventSalesAmount">
        <a-input-number :default-value="10"  :formatter="value => `¥ ${value}`" style="width: 100%;" v-model="form.eventSalesAmount"  :min="0" :disabled="applyType == 3"  :step="0.01" placeholder="请输入奖品预算金额"  />
      </a-form-model-item>
      <a-form-model-item ref="prizeBudgetAmount" label="奖品预算金额" prop="prizeBudgetAmount">
        <a-input-number :default-value="10" :formatter="value => `¥ ${value}`" style="width: 100%;" v-model="form.prizeBudgetAmount" :min="0" :disabled="applyType == 3"  :step="0.01" placeholder="请输入活动售卖金额"  />
      </a-form-model-item>



    <a-form-model-item label="活动申请方信息" required>
<!--      <a-radio-group v-model="form.customerType" @change="customerTypeChange" :disabled="applyType == 3">-->
      <a-radio-group v-model="customerType" @change="customerTypeChange" :disabled="applyType == 3">
        <a-radio :value="2">公司</a-radio>
        <a-radio :value="1">个人</a-radio>
      </a-radio-group>
      <a-select v-model="form.customerId" placeholder="请选择客户" :disabled="applyType == 3"
                :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                show-search :default-value="null" option-label-prop="label" @change="selectDept">
        <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in customerData"
                         :key="index">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

      <a-form-model-item label="客户部门" >
        <a-select v-model="customerDeptId" :placeholder="applyType == 3 ? '-': '请选择部门'" :disabled="applyType == 3"
                  :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                  show-search :default-value="null" option-label-prop="label">
          <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in deptList"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="是否需要开发票" prop="invoice">
        <a-select v-model="form.invoice" placeholder="请选择是否需要开票" :disabled="applyType == 3">
          <a-select-option :value="1">需要</a-select-option>
          <a-select-option :value="2">不需要</a-select-option>
          <a-select-option :value="3">未知</a-select-option>
        </a-select>
      </a-form-model-item>

    <a-form-model-item label="活动规则" prop="rule">
      <a-textarea v-model="form.rule"  maxlength="500" placeholder="请输入活动规则" :disabled="applyType == 3" />
    </a-form-model-item>
      <div style="width: 100%;text-align: center" v-if="applyType != 3">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </div>
<!--      <a-button style="margin-left: 10px;" @click="resetForm">Reset</a-button>-->
  </a-form-model>
  </a-modal>

</template>
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import {request} from "@/utils/request";
export default {
  data() {
    return {
      applyType:1,//1新增 2编辑
      moment,
      visible:false,
      labelCol: { span: 5},
      wrapperCol: { span: 17 },
      dateValue: undefined,
      showTimeValue:undefined,
      ranges:{
        '今天': [moment(), moment()],
        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '当月': [moment().startOf('month'), moment().endOf('month')],
        '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      other: '',
      form: {
        theme: '',
        customerDeptId:undefined,//部门
        eventSalesAmount:undefined,//售卖金额
        prizeBudgetAmount: undefined, // 活动预算
        invoice:undefined,//需要发票
        rule:'',//活动规则
        startTime:'',//活动开始时间
        endTime:'',//活动结束时间
        showTime:'',
        closeTime:'',
        prizeFormat:1,//奖品形式
        type: 1,
        customerType:2,//1个人2企业
        distributorCode:'20220601001',
        sellerId:undefined
      },
      rules: {
        theme: [{ required: true, message: '请输入活动主题', trigger: 'blur' },],
        region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
        distributorCode: [{ required: true, message: '请选择分销商', trigger: 'change' }],
        type: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择活动日期', trigger: 'change' }],
        showTime: [{ required: true, message: '请选择用户端展示时间', trigger: 'change' }],
        prizeBudgetAmount:[{ required: true, message: '请输入售卖预算', trigger: 'blur' },],
        eventSalesAmount:[{ required: true, message: '请输入售卖金额', trigger: 'blur' },],
        // customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        sellerId: [{ required: true, message: '请选择卖方主体', trigger: 'change' }],
        invoice: [{ required: true, message: '请选择是否需要开票', trigger: 'change' }],
        prizeFormat: [{ required: true, message: '请选择奖品形式', trigger: 'change' }],
        rule: [{ required: true, message: '请输入活动规则', trigger: 'blur' }],
      },
      customerData:[],//客户列表
      sellerList:[],//卖方主体
      deptList:[],//部门
      customerType:2,
      customerDeptId:undefined,//部门
    };
  },
  mounted(){
   this.getInitData();
  },
  watch:{
    applyType(){
      if(this.applyType == 1) {
        this.form = {
          theme: '',
          eventSalesAmount:undefined,//售卖金额
          prizeBudgetAmount: undefined, // 活动预算
          invoice:undefined,//需要发票
          rule:'',//活动规则
          startTime:undefined,//活动开始时间
          endTime:undefined,//活动结束时间
          showTime:undefined,
          closeTime:undefined,
          prizeFormat:undefined,//奖品形式
          type: 1,
          customerType:2,//1个人2企业
          distributorCode:'20220601001',
          sellerId:undefined
        }
        this.dateValue = undefined
        this.showTimeValue = undefined
      }
      this.getDeptList();
    }
  },
  methods: {
    selectDept() {
      this.customerDeptId = undefined
      this.getDeptList()
    },
    customerTypeChange(e){
      console.log(e.target.value,'eee')
      this.form.customerType = e.target.value;
    },
    formatDateMin(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      return result;
    },
    getInitData(){
      this.showTimeValue = [this.form.showTime,this.form.closeTime]
      this.dateValue = [this.form.startTime,this.form.endTime]
      // 客户列表
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
        this.customerData = res.data.data
      })
      // 卖方主体
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
        this.sellerList = res.data.data;
      })
    },
    getDeptList(){
      console.log(this.form.customerId,'this.form.customerId')

      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/dept/list/'+this.form.customerId, 'get').then(res => {
      // request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/dept/list/65b580ae-c5c2-45ba-aa68-1f5b6c1ba985', 'get').then(res => {
        this.deptList = res.data.data;
      })

    },
    // 活动开始结束时间
    dateChange(date,dateString) {
      // this.form.startTime = date[0]
      // this.form.endTime = date[1]
      this.form.startTime = dateString[0].toString();
      this.form.endTime = dateString[1].toString();
      this.dateValue = date;
    },
    // 用户端展示时间
    dateShowChange(date,dateString) {
      // this.form.showTime = date[0]
      // this.form.closeTime = date[1]
      this.form.showTime = dateString[0].toString();
      this.form.closeTime = dateString[1].toString();
      this.showTimeValue = date;
    },
    onSubmit() {
      this.form.customerType = this.customerType;
      this.form.customerDeptId = this.customerDeptId;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(!this.form.customerId) {
            this.$message.error('请选择客户！')
            return false;
          }
          this.$emit('getFormData',this.form)
          this.visible = false;
          // this.form.customerType
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.dateValue = undefined;
      this.showTimeValue = undefined;
    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      console.log(result,'res')
      return result
    },
  },
};
</script>
